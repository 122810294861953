<template>
  <homework-common type-view="view-homework"
                   :homework="homework"
                   :paging="paging"
                   v-loading="loading"
                   @changePage="handleCurrentChange">
  </homework-common>
</template>

<script>
import { GET_SCHEDULE_HOMEWORK } from "@/core/services/store/classes/classes.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import homeworkCommon from "@/views/pages/classrooms/homework-common";


export default {
  name: "schedule-homework",
  components: {
    homeworkCommon
  },
  data() {
    return{
      loading: false,
      homework: [],
      currentPage: 1,
      paging: {
        current_page: 1,
        per_page: 1,
        total: 0,
      },
    }
  },
  created() {
    this.showHomeWork();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Nội dung bài tập về nhà", icon: 'el-icon-document-copy'}
    ]);
  },
  methods: {
    showHomeWork: function (){
      this.loading = true;
      const params = {
        classroom_id: this.$route.params.classroom_id,
        learning_schedule_id: this.$route.params.learning_schedule_id,
        page: this.currentPage
      };
      this.$store.dispatch(GET_SCHEDULE_HOMEWORK, params).then((res) => {
        this.homework = res.data.data;
        this.paging = { current_page: res.data.current_page, total:  res.data.total }
      }).catch((error) => {
        this.$notify({
          type: 'error',
          message: error.data.message,
          title: 'Thất bại'
        });
      }).finally(() => { this.loading = false })
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.showHomeWork();
    }
  },
}
</script>

<style scoped>
</style>